<template>
  <div>
    <pageTitle :title="'业务编号：'+ orderInfo.job_no" style="background: #fff">
      <template slot="right">
        <div class="statusbox">
          <div>{{ orderInfo.customer_name_zh | textFormat }}</div>
          <div>|</div>
          <div>{{ orderInfo.contact_name_zh | textFormat }}</div>
          <div class="status">{{ orderInfo.status | status_format }}</div>
        </div>
      </template>
    </pageTitle>
    <pageTitle title="货物跟踪" style="background: #fff; margin-top: 10px;">
      <template slot="right">
        <el-button
          type="primary"
          size="small"
          style="margin-right: 12px"
          @click="sopConfig"
          >配置跟踪显示</el-button
        >
        <el-button
          size="small"
          style="margin-right: 12px"
          @click="$router.back()"
          >返回</el-button
        >
      </template>
    </pageTitle>
    <div class="content">
      <div class="sopinfo" v-loading="loading">
        <div class="head">
          <div class="port">
            <div class="name"><i class="el-icon-location"></i>起运港</div>
            <div> {{ orderInfo.pol_name_en }}</div>
          </div>
          <div class="line">----------------------</div>
          <div class="port">
            <div class="name"><i class="el-icon-location"></i>目的港</div>
            <div>{{ orderInfo.pod_name_en }}</div>
          </div>
        </div>
        <div class="portinfo">
          <div class="state">
            <div class="item" v-for="(item,index) in sop_info" :key="index">
              <div class="info">
                <el-tooltip placement="bottom-start" effect="light" v-if="item.updated_time">
                  <div class="node_name" :class="item.updated_time ? 'node_name_active':''">
                      <span>{{item.node_name_zh ? item.node_name_zh:'-'}}</span>
                  </div>
                  <div slot="content">更新时间：<br/>{{item.updated_time | secondFormat('LLLL')}}</div>
                </el-tooltip>
                <template v-else>
                  <div class="node_name" :class="item.updated_time ? 'node_name_active':''">
                      <span>{{item.node_name_zh ? item.node_name_zh:'-'}}</span>
                  </div>
                </template>
                <div class="node_detail">
                  <span :class="item.updated_time ? 'active':''">{{item.details_name_zh | textFormat}}</span>
                </div>
              </div>
            </div>
            <div class="nodata" v-if="sop_info.length == 0">暂无信息</div>
          </div>
        </div>
      </div>
      <tabs class="tabs" @upEtd="getEtd"></tabs>
      <!-- 配置跟踪节点信息 -->
      <dialogSetColum ref="dialogSetColumRef" :tableType='7' @getCheckColum="getCheckColum"></dialogSetColum>
    </div>
  </div>
</template>

<script>
import pageTitle from "~bac/components/common/pageTitle";
import tabs from "~bac/components/dealManagement/myEntrust/detailsOrdered/tabs";
import dialogSetColum from "~bac/components/dealManagement/myEntrust/detailsOrdered/dialogSetColum";

export default {
  name: "detailsOrdered",
  components: { pageTitle, tabs, dialogSetColum },
  data() {
    return {
      sopList: [],
      dialogFormVisible: false,
      loading: false,
      sop_info: [],
      orderInfo: {},
      pageTitleDesc: ''
    }
  },
  mounted() {
    this.getSeaSopstatus();
  },
  methods: {
    sopConfig(){
      this.$refs["dialogSetColumRef"].openDialog();
    },
    getCheckColum(e){
      this.getSeaSopstatus();
    },
    viewAllFunc(){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.shippingOrder.lookAll",
        content: JSON.stringify({
          id: this.$route.query.id || ''
        }),
      });
      this.dialogFormVisible = true;
    },
    async getSeaSopstatus() {
      let params = {
        job_no: this.$route.query.orderString,
        user_id: this.USER_ID,
        order_id: this.$route.query.id
      }
      let res = await this.$store.dispatch("API_freight/getAllOrderInfo", params);
      this.loading = false;
      if (res.success && res.data) {
        this.sop_info = res.data;
      }
    },
    getEtd(data) {
      this.orderInfo = data;
    },
  },
  filters:{
    status_format(e) {
      let obj = {
        0: "待审核",
        1: "正操作",
        2: "预提交",
        3: "已提交",
        4: "已审核",
        5: "已退关",
        6: "已拒接",
      }
      return obj[e]
    },
  }
}
</script>

<style scoped lang="less">
.content {
  border-top: 1px solid #cccccc;
  .sopinfo{
    width: 100%;
    background: white;
    padding: 20px;
    .head{
      display: flex;
      height: 60px;
      align-items: center;
      font-weight: bold;
      .line{
        margin-left: 20px;
        color: red;
      }
      .port{
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .name{
          margin-right: 20px;
          i{color:#027fff; font-size: 16px; margin: 10px;}
        }
      
      }
    }
    .portinfo{
      display: flex;
      padding: 20px;
      .state{
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        .item{
          width: 132px;
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          &:last-child{
            .info{
              &:after{
                content: none;
                width: 0;
              }
            }
          }
          .spot{
            width: 8px;
            height: 8px;
            background: #CCCCCC;
            border-radius: 50%;
            margin-left: 5px;
            margin-right: 20px;
          }
          
          .info{
            width: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            &:after{
            position: absolute;
            left: 86px;
            top: 40px;
            content: ' ';
            width: 41px;
            height: 1px;
            border-bottom: 1px dashed #EBEBEB;
            }
            .node_name{
              font-size: 16px;
              border: 1px solid #EBEBEB;
              width: 80px;
              height: 80px;
              padding: 20px;
              border-radius: 50%;
              align-items: center;
              display: flex;
              justify-content: center;
              text-align: center;
              overflow: hidden;
              word-break: break-all;
            }
            .node_name_active{
              color: #20B54E;
              border: 1px solid #20B54E;
            }
            .node_detail{
              font-size: 12px;
              color: #999999;
              border-radius: 2px;
              text-align: center;
              margin-top: 10px;
              word-break: break-all;
            }
            .active{
              font-size: 12px;
              color: #20B54E;
            }
            .date{
              font-size: 12px;
              width: 80px;
              color: #999;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
 
}
.tabs {
  margin-top: 10px;
  background: #FFFFFF;
  padding: 12px;
}

.marginT10 {
  margin-top: 10px;
}

.marginT38 {
  margin-top: 38px;
}

.colorGreen {
  color: rgb(82, 196, 26)
}

.color999 {
  color: #999999;
}
.colorGreen {
  color: rgb(82, 196, 26)
}
.color999 {
  color: #999999;
}
.status_update_time {
  font-size: 14px;
  color: #cccccc;
  height: 18px;
}

.colorBlue {
  color: #20a0ff;
}

.colorRed {
  color: red;
}

.color999 {
  color: #999999;
}

.point {
  cursor: pointer;
}
.viewbox{
  padding: 0 20px;
}
.view2Item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px dashed #ccc;
  align-items: center;
}
.yuan {
  width: 13px;
  height: 13px;
  background: #888888;
  border-radius: 50%;
  margin-right: 13px;
}
 .date {
    font-size: 12px;
    margin-left: 20px;
    color: #888888;
  }
  .limit{
    margin-left: 20px;
    font-size: 14px;
    color: red;
  }
.remarks{
  margin-top: 10px;
  font-size: 12px;
}
.bg7FFF00 {
  background: #20a0ff!important;
}
.color7FFF00 {
  color: #20a0ff!important;
}
.color888 {
  color: #888888;
}
.statusbox{
  display: flex;
  align-items: center;
  div{
    margin-left: 10px;
  }
  .status{
    color: white;
    font-size: 16px;
    font-weight: 500;
    background-color: #f26161;
    padding: 16px 30px;
  }
}
</style>